<template>
  <!--begin::Quotation Listing-->
  <div class="quotation-template">
    <PageHeaderCount
      moduleType="quotation"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_quotations"
      countkey="quotation_status_count"
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'quotation-listing'"
      v-if="getPermission('quotation:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0 my-auto">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('quotation:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="updateBulkAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  depressed
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0 mxw-200">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <template v-if="item.value == 'submit_for_approval'">
                          <template v-if="userDetails.role == 4">
                            <v-list-item-title
                              v-text="'Submied For Approval'"
                            ></v-list-item-title>
                          </template>
                          <template v-else>
                            <v-list-item-title
                              v-text="'Pending For Approval'"
                            ></v-list-item-title>
                          </template>
                        </template>
                        <template v-else>
                          <v-list-item-title
                            v-text="item.text"
                          ></v-list-item-title>
                        </template>
                        <template v-if="item.value == 'submit_for_approval'">
                          <template v-if="userDetails.role == 4">
                            <v-list-item-subtitle
                              v-text="'Submied For Approval'"
                            ></v-list-item-subtitle>
                          </template>
                          <template v-else>
                            <v-list-item-subtitle
                              v-text="'Pending For Approval'"
                            ></v-list-item-subtitle>
                          </template>
                        </template>
                        <!--   <template v-else>
                          <v-list-item-subtitle
                            v-text="item.description"
                          ></v-list-item-subtitle>
                        </template> -->
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_quotations
                          }}</template>
                          <template v-else>{{
                            item.quotation_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-flex>
              <v-flex
                class="pt-0 pr-0 justify-flex-end d-flex quotation-listing-button-right"
              >
                <template v-if="getPermission('quotation:create')">
                  <v-btn
                    v-if="false"
                    depressed
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    :to="getDefaultRoute('quotation.create')"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                  <v-btn
                    v-if="false"
                    depressed
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="exportQuotation"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-database-export</v-icon> Export
                  </v-btn>
                </template>
                <v-btn
                  depressed
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  depressed
                  v-if="!isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'quotation',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button pr-0"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <v-menu content-class="custom-menu-list" offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="260"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                    v-on:change="updateVisible()"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          v-on:change="updateVisible()"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <v-menu offset-y left v-if="false">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          depressed
                          :disabled="dataLoading"
                          class="ml-2 custom-bold-button"
                          color="cyan white--text"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          {{ entityObject.name }}
                        </v-btn>
                      </template>
                      <span>Entity</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in entityList"
                      :key="index"
                      link
                      v-on:click="updateEntity(item)"
                    >
                      <v-list-item-title class="font-weight-600">{{
                        item.name
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <PageTips v-if="false" module="quotation"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Quotation"
          :basicSearchFields="[
            'barcode',
            'reference',
            'title',
            'sales',
            'prepared_by',
            /* 'attention', */
            'company_name',
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData2="rowData2"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData2.length > 0">
                  <tr
                    v-for="(data, index) in rowData2"
                    :key="index"
                    link
                    class="quotation-listing-row"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <div class="d-inline-grid">
                            <Barcode
                              route="quotation.detail"
                              :barcode="data.barcode"
                              :id="data.id"
                            ></Barcode>
                            <!--    <v-chip
                              label
                              small
                              :color="getColorType(data.type)"
                              text-color="white"
                              class="text-center text-uppercase font-weight-600 mb-1"
                              >{{ getColorText(data.type) }}</v-chip
                            > -->
                            <v-chip
                              label
                              color="red"
                              class="text-white p-3 mb-1"
                              v-if="data.q_duplicate_barcode"
                              outlined
                              small
                              >{{ data.q_duplicate_barcode }}</v-chip
                            >
                            <v-chip
                              label
                              color="red"
                              class="text-white p-3 mb-1"
                              v-if="data.enquiry_barcode"
                              outlined
                              small
                              >{{ data.enquiry_barcode }}</v-chip
                            >
                            <v-chip
                              label
                              color="red"
                              class="text-white p-3 mb-1"
                              v-if="data.q_requote_barcode"
                              outlined
                              small
                              >{{ data.q_requote_barcode }}</v-chip
                            >
                            <!--  <v-chip
                              label
                              color="red"
                              class="text-white p-3 mb-1"
                              v-if="data.q_revise_barcode"
                              outlined
                              small
                              >{{ data.q_revise_barcode }}</v-chip
                            > -->
                            <!--  <Barcode
                              v-if="data.contract"
                              route="contract.detail"
                              :barcode="data.contract.barcode"
                              :id="data.contract.id"
                            ></Barcode> -->
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'date'">
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.added_at"
                          >
                            <b>Created: </b> {{ formatDate(data.added_at) }}
                          </p>
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.validity_till && data.validity_till > 0"
                          >
                            <b>Validity: </b> {{ getValidityDate(data) }}({{
                              data.validity_till
                            }}Days)
                          </p>
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.sent_date"
                          >
                            <b>Sent: </b> {{ formatDate(data.sent_date) }}
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'job_title'">
                          <div class="quotation-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Title: </b>{{ data.job_title }}
                            </p>
                            <!-- <p class="m-0 custom-nowrap-ellipsis">
                              <b>Visit Count: </b>
                              <template
                                v-if="data && data.visit_multiple_count > 0"
                              >
                                <span
                                  class="count-radius"
                                  style="
                                    background-color: green !important;
                                    color: white;
                                    margin-left: 19px;
                                  "
                                  >{{ data.visit_multiple_count }}</span
                                >
                              </template>
                              <span
                                v-else
                                style="
                                  background-color: #93959f !important;
                                  color: white;
                                  margin-left: 19px !important;
                                "
                                class="ml-1 count-radius white-text"
                                >0</span
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Invoice Count: </b>
                              <template v-if="data && data.invoice_count > 0">
                                <span
                                  style="
                                    background-color: red !important;
                                    color: white;
                                  "
                                  class="ml-1 count-radius bg-light-success"
                                  >{{ data.invoice_count }}</span
                                >
                              </template>
                              <span
                                v-else
                                style="
                                  background-color: #93959f !important;
                                  color: white;
                                "
                                class="ml-1 count-radius white-text"
                                >0</span
                              >
                            </p> -->
                            <!--  <p
                              class="m-0 custom-nowrap-ellipsis-two-line"
                              v-if="data.billing"
                            >
                              <b>Company Address: </b>
                              <template v-if="data.billing.property_address"
                                >{{ data.billing.property_address }}
                              </template>
                            </p> -->
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'costing'">
                          <div class="quotation-listing-customer">
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="data.costing"
                            >
                              {{ data.costing }}
                            </p>
                            <template v-else
                              ><em class="text--secondary"
                                >No costing</em
                              ></template
                            >
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'reference'">
                          <div class="quotation-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <template v-if="data.reference">{{
                                data.reference
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Reference</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'basic_detail'">
                          <div class="quotation-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Sales representative: </b>
                              <template v-if="data.sales_user">
                                <v-chip
                                  v-if="
                                    data &&
                                    data.sales_user &&
                                    data.sales_user.display_name
                                  "
                                  small
                                  class="mb-1 mr-1"
                                >
                                  <v-avatar left small>
                                    <img
                                      v-if="
                                        data.sales_user &&
                                        data.sales_user.profile_logo &&
                                        data.sales_user.profile_logo.file
                                      "
                                      :src="
                                        data.sales_user.profile_logo.file.url
                                      "
                                    />
                                    <img
                                      v-else
                                      src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                                    />
                                  </v-avatar>
                                  <span>{{
                                    data.sales_user.display_name
                                  }}</span>
                                </v-chip>
                              </template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Sales representative</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Prepared by: </b>
                              <template
                                v-if="
                                  data.prepared_user &&
                                  data.prepared_user.display_name
                                "
                                >{{ data.prepared_by }}
                                <v-chip
                                  v-if="
                                    data &&
                                    data.prepared_user &&
                                    data.prepared_user.display_name
                                  "
                                  small
                                  class="mb-1 mr-1"
                                >
                                  <v-avatar left small>
                                    <img
                                      v-if="
                                        data.prepared_user &&
                                        data.prepared_user.profile_logo &&
                                        data.prepared_user.profile_logo.file
                                      "
                                      :src="
                                        data.prepared_user.profile_logo.file.url
                                      "
                                    />
                                    <img
                                      v-else
                                      src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                                    />
                                  </v-avatar>
                                  <span>{{
                                    data.prepared_user.display_name
                                  }}</span>
                                </v-chip>
                              </template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Prepared by</em
                                ></template
                              >
                            </p>

                            <!-- <p class="m-0 custom-nowrap-ellipsis d-flex">
                              <b> Rating: </b>
                              <template v-if="data.rating"
                                ><v-rating
                                  readonly
                                  dense
                                  v-model.trim="data.rating"
                                  background-color="orange lighten-3"
                                  color="orange"
                                ></v-rating
                              ></template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Rating</em
                                ></template
                              >
                            </p> -->
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'status'">
                          <div class="quotation-listing-status">
                            <CustomStatus
                              small
                              :status="data.status"
                              endpoint="quotation/status"
                            ></CustomStatus>
                            <div class="mb-1">
                              <template
                                v-if="
                                  data.pending_for_approver == 1 &&
                                  data.status == 1
                                "
                              >
                                <v-chip
                                  color="red"
                                  text-color="white"
                                  label
                                  small
                                  class="p-3 ml-2 mb-1"
                                >
                                  {{ data.pending_approver }}
                                </v-chip>
                              </template>
                            </div>
                            <div class="mb-1">
                              <v-chip
                                v-if="
                                  data &&
                                  data.quotation_duplicate &&
                                  data.quotation_duplicate > 0
                                "
                                label
                                small
                                color="red"
                                outlined
                                class="text-white p-3 ml-2 mb-1"
                                >Duplicate</v-chip
                              >
                              <v-chip
                                v-if="
                                  data &&
                                  data.quotation_requote &&
                                  data.quotation_requote > 0
                                "
                                label
                                small
                                color="red"
                                outlined
                                class="text-white p-3 ml-2"
                                >Re-Quote</v-chip
                              >
                              <div class="mb-1">
                                <template v-if="data.is_revised">
                                  <v-tooltip
                                    top
                                    content-class="custom-top-tooltip"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-chip
                                        v-bind="attrs"
                                        v-on="on"
                                        label
                                        color="red"
                                        outlined
                                        small
                                        class="text-white p-3 ml-2"
                                      >
                                        REVISED
                                      </v-chip>
                                    </template>
                                    <span>Quotation is Revised</span>
                                  </v-tooltip>
                                </template>
                              </div>
                              <div class="mb-1">
                                <template
                                  v-if="
                                    data &&
                                    data.quotation_revise &&
                                    data.quotation_revise > 0 &&
                                    !data.is_revised
                                  "
                                >
                                  <v-tooltip
                                    top
                                    content-class="custom-top-tooltip"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-chip
                                        v-bind="attrs"
                                        v-on="on"
                                        label
                                        color="teal"
                                        outlined
                                        small
                                        class="text-white p-3 ml-2"
                                      >
                                        REVISION
                                      </v-chip>
                                    </template>
                                    <span
                                      >Revision of
                                      {{ data.q_revise_barcode }}</span
                                    >
                                  </v-tooltip>
                                </template>
                              </div>
                              <!--  <v-chip
                                  v-if="data && data.quotation_revise && data.quotation_revise>0" 
                                  label
                                  small
                                  color="red"
                                  outlined
                                  class="text-white p-3 ml-2"
                                  >Revised</v-chip> -->
                            </div>
                            <div
                              class="mb-1"
                              v-if="
                                data?.invoice_count &&
                                data?.invoice_count > 0 &&
                                data.status == 2
                              "
                            >
                              <v-chip
                                color="orange"
                                text-color="white"
                                label
                                small
                                class="ml-2 mb-1 font-weight-600"
                              >
                                Invoiced
                              </v-chip>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'company_name'">
                          <div class="quotation-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <template
                                v-if="
                                  data &&
                                  data.customer &&
                                  data.customer.company_name
                                "
                              >
                                {{ data.customer.company_name }}
                              </template>
                              <em class="text-muted" v-else> no company</em>
                            </p>

                            <template
                              v-if="
                                false && data.customer.contact_person_count > 1
                              "
                            >
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="ml-2"
                                v-on:click.prevent.stop="
                                  customerRedirect(data.customer.id)
                                "
                              >
                                <span class="font-size-16 font-weight-500">
                                  +{{
                                    data.customer.contact_person_count - 1
                                  }}
                                  More</span
                                >
                              </v-chip>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'display_name'">
                          <div class="quotation-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              {{
                                data &&
                                data.customer &&
                                data.customer.display_name
                              }}
                              <!-- {{ data.customer.display_name }} -->
                            </p>

                            <template
                              v-if="
                                false && data.customer.contact_person_count > 1
                              "
                            >
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="ml-2"
                                v-on:click.prevent.stop="
                                  customerRedirect(data.customer.id)
                                "
                              >
                                <span class="font-size-16 font-weight-500">
                                  +{{
                                    data.customer.contact_person_count - 1
                                  }}
                                  More</span
                                >
                              </v-chip>
                            </template>
                          </div>
                        </template>

                        <template v-else-if="cols.field == 'company_detail'">
                          <div class="quotation-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Email: </b>
                              <template
                                v-if="
                                  data.billing_person &&
                                  data.billing_person.primary_email
                                "
                              >
                                {{ data.billing_person.primary_email }}
                              </template>
                              <em class="text-muted" v-else> no email</em>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Phone: </b>
                              <template
                                v-if="
                                  data.billing_person &&
                                  data.billing_person.primary_phone
                                "
                              >
                                {{ data.billing_person.primary_phone }}
                              </template>
                              <em class="text-muted" v-else>
                                no phone number</em
                              >
                            </p>
                            <template
                              v-if="
                                false && data.customer.contact_person_count > 1
                              "
                            >
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="ml-2"
                                v-on:click.prevent.stop="
                                  customerRedirect(data.customer.id)
                                "
                              >
                                <span class="font-size-16 font-weight-500">
                                  +{{
                                    data.customer.contact_person_count - 1
                                  }}
                                  More</span
                                >
                              </v-chip>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'rating'">
                          <div class="quotation-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis d-flex">
                              <template v-if="data.rating"
                                ><v-rating
                                  readonly
                                  dense
                                  v-model.trim="data.rating"
                                  background-color="orange lighten-3"
                                  color="orange"
                                ></v-rating
                              ></template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Rating</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'amount'">
                          <div class="quotation-listing-amount">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Sub Total: </b>
                              <span class="font-weight-700">
                                {{
                                  $accountingJS.formatMoney(data.sub_total)
                                }}</span
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Tax: </b>
                              {{ $accountingJS.formatMoney(data.tax_amount) }}
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'total'">
                          <div class="quotation-listing-amount">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Total: </b>
                              <span class="font-weight-700">
                                {{
                                  $accountingJS.formatMoney(data.total)
                                }}</span
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              <template v-if="getDateCountAll(data.added_at)">{{
                                data.created_at
                              }}</template>
                              <template v-else>{{
                                formatDateTime(data.added_at)
                              }}</template>
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td colspan="8" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no quotation at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Quotation</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <v-col md="6">
                    <v-menu
                      v-if="false"
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          flat
                          label="Dates Range"
                          hide-details
                          solo
                          clearable
                          v-on:click:clear="dates = []"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :value="formattedDate"
                          color="cyan"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="cyan"
                        range
                        v-model="dates"
                      ></v-date-picker>
                    </v-menu>
                    <DateRangePicker
                      placeholder="Data Range"
                      v-model="dates"
                      v-on:click:clear="clearFilter('dates')"
                      class="mr-2"
                    ></DateRangePicker>
                  </v-col>

                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <Datepicker
                        v-if="field == 'open_till'"
                        solo
                        flat
                        clearable
                        v-model.trim="listingSearch[field]"
                        label="Open Still"
                      ></Datepicker>

                      <v-text-field
                        v-else
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="
                          field == 'sales'
                            ? 'Sales Person'
                            : field == 'customer_detail[value]'
                            ? 'Customer Details[Phone Number]'
                            : getFilterLabel(field, 'Quotation')
                        "
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              depressed
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              depressed
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <ImportTemplate
          :dialog="importDialog"
          sample-url="quotation/import-sample"
          api-url="quotation/import"
          title="Import Quotation"
          v-on:close="importDialog = false"
        ></ImportTemplate>

        <template v-if="entityDialog">
          <EntityDialog
            :entity-dialog="entityDialog"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectEntity="selectEntity"
          ></EntityDialog>
        </template>
        <template v-if="entity && customerDialog">
          <CustomerDialog
            :entity="entity"
            :customerDialog="customerDialog"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomer="selectCustomer"
          ></CustomerDialog>
        </template>
        <template v-if="customerPersonDialog">
          <CustomerPersonDialog
            disabled-auto-select
            :customerPersonDialog="customerPersonDialog"
            :customer="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerPerson="selectCustomerPerson"
          >
          </CustomerPersonDialog>
        </template>
        <template v-if="customerPropertyDialog">
          <CustomerPropertyDialog
            disabled-auto-select
            :customerPropertyDialog="customerPropertyDialog"
            :customer="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerProperty="selectCustomerProperty"
          ></CustomerPropertyDialog>
        </template>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Quotation Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import QuotationMixin from "@/core/lib/quotation/quotation.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, DOWNLOAD } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import { saveAs } from "file-saver";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import EntityDialog from "@/view/pages/partials/Select-Entity.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import JwtService from "@/core/services/jwt.service";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";
import DateRangePicker from "@/view/components/DateRangePicker";
//import { map } from "lodash";
import moment from "moment-timezone";
export default {
  mixins: [CommonMixin, ListingMixin, QuotationMixin],
  name: "quotation-list",
  data() {
    return {
      userDetails: {},
      exportLoading: false,
      dates: [],
      pageModule: "quotation-listing",
      routeAPI: "quotation",
      routeName: "quotation",
      routeDetailName: "quotation.detail",
      importDialog: false,
      status: "all",
      statusList: [],
      customThead: [],
      rowData2: [
        {
          id: 1,
          revise: null,
          rating: 1,
          barcode: "QT000005",
          costing: null,
          enquiry_barcode: null,
          enquiry_id: 0,
          reference: null,
          your_reference: null,
          quotation_duplicate_date: null,
          quotation_requote_date: null,
          quotation_revise_date: null,
          ticket_convt_date: null,
          visit_convt_date: null,
          service_form_convt_date: null,
          quotation_duplicate: "0",
          q_duplicate_barcode: null,
          quotation_requote: "0",
          q_requote_barcode: null,
          quotation_revise: 0,
          q_revise_barcode: null,
          visit_barcode: null,
          ticket_barcode: null,
          customer: {
            id: 1,
            barcode: "C-000004",
            customer_refrence_id: null,
            display_name: "Business Thrust Techsoft Pvt Ltd.",
            company_name: "Business Thrust Techsoft Pvt Ltd.",
            company_email: null,
            company_number: null,
            company_fax: null,
            company_type: "company",
            reffered_by: "0",
            client_reminders: "1",
            client_follow_up_emails: "1",
            print_email_on_work_order: "1",
            website: "www.bthrust.com",
            uen: null,
            address_line_1: "",
            address_line_2: "",
            unit_number: "",
            postal_code: "",
            country: "Singapore",
            state: "",
            city: "",
            phone: "",
            security_code: "YVJGWITA",
            group: "Commercial",
            group_id: "1",
            entity: "Business Thrust Pte Ltd.",
            entity_id: 1,
            sap_customer_no: null,
            cust_vend_ref_no: null,
            customer_remark: null,
            primary: true,
            activated: true,
            user: "0",
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "1",
            updated_at: "2024-08-24 11:48:19",
            category: null,
            category_color: null,
            can_login: 0,
            role: 3,
            contact_person_count: "2",
            profile_logo: null,
          },
          pending_for_approver: "0",
          property_person: {
            id: 1,
            uuid: null,
            barcode: "CP-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          validity_till: "30",
          type: "1",
          sales_id: 3,
          prepared_by_id: 1,
          billing_person: {
            id: 1,
            uuid: null,
            barcode: "CP-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          visit_converted: "0",
          visit_converted_date: null,
          visit: "0",
          invoice_converted: "0",
          invoice_converted_date: null,
          property: {
            id: 1,
            barcode: "P-000008",
            property_name: null,
            customer: 1,
            supplier: "0",
            contact_person: 1,
            is_same: "1",
            is_tenant: 0,
            unit_no: "#01-583",
            street_1: "ATM, Bendemeer Road Branch (UOB)",
            street_2: "25 Bendemeer Road",
            zip_code: "330025",
            country: "0",
            zone_territory: null,
            state: "0",
            city: "0",
            latitude: null,
            longitude: null,
            remarks: null,
            entity: "Business Thrust Pte Ltd.",
            entity_id: "1",
            type: 1,
            activated: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            property_address:
              "ATM, Bendemeer Road Branch (UOB) 25 Bendemeer Road #01-583 330025",
          },
          billing: {
            id: 1,
            barcode: "P-000008",
            property_name: null,
            customer: 1,
            supplier: "0",
            contact_person: 1,
            is_same: "1",
            is_tenant: 0,
            unit_no: "#01-583",
            street_1: "ATM, Bendemeer Road Branch (UOB)",
            street_2: "25 Bendemeer Road",
            zip_code: "330025",
            country: "0",
            zone_territory: null,
            state: "0",
            city: "0",
            latitude: null,
            longitude: null,
            remarks: null,
            entity: "Business Thrust Pte Ltd.",
            entity_id: "1",
            type: 1,
            activated: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            property_address:
              "ATM, Bendemeer Road Branch (UOB) 25 Bendemeer Road #01-583 330025",
          },
          job_title: "Lorem Ipsum is simply dummy text of the printing a",
          sales: null,
          prepared_by: null,
          attention: null,
          total: 1918.4,
          sub_total: 2000,
          is_contract: 0,
          discount_value: 12,
          tax_amount: 158.4,
          adjustment: 0,
          status: 2,
          date: "2024-08-30",
          open_till: "2024-08-30",
          is_revised: false,
          sent_date: null,
          added_by: {
            id: 1,
            display_name: "Admin",
            user_email: "admin@bthrust.com",
            full_name: "",
          },
          added_at: "2024-08-30 11:27:39",
          updated_by: null,
          updated_at: "2024-08-31 14:31:18",
          qt_status_text: "Accepted",
          qt_status_value: "accepted",
          qt_status_color: "green",
          qt_status_textcolor: "white",
          visit_multiple_count: 0,
          invoice_count: 0,
          created_at: "2 days ago",
          modified_at: "1 day ago",
          contract: null,
          sales_user: {
            id: 3,
            title: "Mr.",
            first_name: "John",
            last_name: "Doe",
            display_name: "John Doe",
            user_name: "John Doe",
            user_email: "johndoe@bthrust.com",
            categories: null,
            password_otp: null,
            user_designation: null,
            phone_number: "+65-98765432",
            signature: null,
            activated: true,
            channel: "engineer",
            role: "2",
            from_amount: "0.00",
            to_amount: "0.00",
            has_parent_approver: "0",
            parent_approver: "0",
            engineer: "2",
            added_by: "1",
            added_at: "2024-08-30 09:03:21",
            updated_by: "0",
            updated_at: "2024-08-30 09:03:21",
            email_signature: null,
            force_password_change: "0",
            full_name: "Mr. John Doe",
            profile_logo: null,
          },
          prepared_user: {
            id: 1,
            title: "Mr.",
            first_name: "Super",
            last_name: "Admin",
            display_name: "Admin",
            user_name: "admin",
            user_email: "admin@bthrust.com",
            categories: null,
            password_otp: null,
            user_designation: null,
            phone_number: "+65-98765432",
            signature: null,
            activated: true,
            channel: "customer",
            role: "1",
            from_amount: "0.00",
            to_amount: "0.00",
            has_parent_approver: "0",
            parent_approver: "0",
            engineer: "0",
            added_by: "1",
            added_at: "2024-08-30 09:03:21",
            updated_by: "0",
            updated_at: null,
            email_signature: null,
            force_password_change: "0",
            full_name: "Mr. Super Admin",
            profile_logo: null,
          },
        },
        {
          id: 1,
          revise: null,
          rating: 1,
          barcode: "QT000004",
          costing: null,
          enquiry_barcode: null,
          enquiry_id: 0,
          reference: null,
          your_reference: null,
          quotation_duplicate_date: null,
          quotation_requote_date: null,
          quotation_revise_date: null,
          ticket_convt_date: null,
          visit_convt_date: null,
          service_form_convt_date: null,
          quotation_duplicate: "0",
          q_duplicate_barcode: null,
          quotation_requote: "0",
          q_requote_barcode: null,
          quotation_revise: 0,
          q_revise_barcode: null,
          visit_barcode: null,
          ticket_barcode: null,
          customer: {
            id: 1,
            barcode: "C-000004",
            customer_refrence_id: null,
            display_name: "Business Thrust Techsoft Pvt Ltd.",
            company_name: "Business Thrust Techsoft Pvt Ltd.",
            company_email: null,
            company_number: null,
            company_fax: null,
            company_type: "company",
            reffered_by: "0",
            client_reminders: "1",
            client_follow_up_emails: "1",
            print_email_on_work_order: "1",
            website: "www.bthrust.com",
            uen: null,
            address_line_1: "",
            address_line_2: "",
            unit_number: "",
            postal_code: "",
            country: "Singapore",
            state: "",
            city: "",
            phone: "",
            security_code: "YVJGWITA",
            group: "Commercial",
            group_id: "1",
            entity: "Business Thrust Pte Ltd.",
            entity_id: 1,
            sap_customer_no: null,
            cust_vend_ref_no: null,
            customer_remark: null,
            primary: true,
            activated: true,
            user: "0",
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "1",
            updated_at: "2024-08-24 11:48:19",
            category: null,
            category_color: null,
            can_login: 0,
            role: 3,
            contact_person_count: "2",
            profile_logo: null,
          },
          pending_for_approver: "0",
          property_person: {
            id: 1,
            uuid: null,
            barcode: "CP-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          validity_till: "60",
          type: "1",
          sales_id: 1,
          prepared_by_id: 2,
          billing_person: {
            id: 1,
            uuid: null,
            barcode: "CP-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          visit_converted: "0",
          visit_converted_date: null,
          visit: "0",
          invoice_converted: "0",
          invoice_converted_date: null,
          property: {
            id: 1,
            barcode: "P-000008",
            property_name: null,
            customer: 1,
            supplier: "0",
            contact_person: 1,
            is_same: "1",
            is_tenant: 0,
            unit_no: "#01-583",
            street_1: "ATM, Bendemeer Road Branch (UOB)",
            street_2: "25 Bendemeer Road",
            zip_code: "330025",
            country: "0",
            zone_territory: null,
            state: "0",
            city: "0",
            latitude: null,
            longitude: null,
            remarks: null,
            entity: "Business Thrust Pte Ltd.",
            entity_id: "1",
            type: 1,
            activated: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            property_address:
              "ATM, Bendemeer Road Branch (UOB) 25 Bendemeer Road #01-583 330025",
          },
          billing: {
            id: 1,
            barcode: "P-000008",
            property_name: null,
            customer: 1,
            supplier: "0",
            contact_person: 1,
            is_same: "1",
            is_tenant: 0,
            unit_no: "#01-583",
            street_1: "ATM, Bendemeer Road Branch (UOB)",
            street_2: "25 Bendemeer Road",
            zip_code: "330025",
            country: "0",
            zone_territory: null,
            state: "0",
            city: "0",
            latitude: null,
            longitude: null,
            remarks: null,
            entity: "Business Thrust Pte Ltd.",
            entity_id: "1",
            type: 1,
            activated: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            property_address:
              "ATM, Bendemeer Road Branch (UOB) 25 Bendemeer Road #01-583 330025",
          },
          job_title: "Quotation Title",
          sales: null,
          prepared_by: null,
          attention: null,
          total: 98.1,
          sub_total: 100,
          is_contract: 0,
          discount_value: 10,
          tax_amount: 8.1,
          adjustment: 0,
          status: 2,
          date: "2024-08-30",
          open_till: "2024-08-30",
          is_revised: false,
          sent_date: null,
          added_by: {
            id: 1,
            display_name: "Admin",
            user_email: "admin@bthrust.com",
            full_name: "",
          },
          added_at: "2024-08-30 11:24:49",
          updated_by: null,
          updated_at: "2024-08-31 14:18:01",
          qt_status_text: "Accepted",
          qt_status_value: "accepted",
          qt_status_color: "green",
          qt_status_textcolor: "white",
          visit_multiple_count: 0,
          invoice_count: 0,
          created_at: "2 days ago",
          modified_at: "1 day ago",
          contract: null,
          sales_user: {
            id: 1,
            title: "Mr.",
            first_name: "Super",
            last_name: "Admin",
            display_name: "Admin",
            user_name: "admin",
            user_email: "admin@bthrust.com",
            categories: null,
            password_otp: null,
            user_designation: null,
            phone_number: "+65-98765432",
            signature: null,
            activated: true,
            channel: "customer",
            role: "1",
            from_amount: "0.00",
            to_amount: "0.00",
            has_parent_approver: "0",
            parent_approver: "0",
            engineer: "0",
            added_by: "1",
            added_at: "2024-08-30 09:03:21",
            updated_by: "0",
            updated_at: null,
            email_signature: null,
            force_password_change: "0",
            full_name: "Mr. Super Admin",
            profile_logo: null,
          },
          prepared_user: {
            id: 2,
            title: "Mr.",
            first_name: "Demo",
            last_name: "User",
            display_name: "Demo",
            user_name: "demo",
            user_email: "demo@bthrust.com",
            categories: null,
            password_otp: null,
            user_designation: null,
            phone_number: "+65-12345678",
            signature: null,
            activated: true,
            channel: "customer",
            role: "1",
            from_amount: "0.00",
            to_amount: "0.00",
            has_parent_approver: "0",
            parent_approver: "0",
            engineer: "0",
            added_by: "1",
            added_at: "2024-08-30 09:03:21",
            updated_by: "0",
            updated_at: null,
            email_signature: null,
            force_password_change: "0",
            full_name: "Mr. Demo User",
            profile_logo: null,
          },
        },
        {
          id: 1,
          revise: null,
          rating: 1,
          barcode: "QT000003",
          costing: null,
          enquiry_barcode: null,
          enquiry_id: 0,
          reference: null,
          your_reference: null,
          quotation_duplicate_date: null,
          quotation_requote_date: null,
          quotation_revise_date: null,
          ticket_convt_date: null,
          visit_convt_date: null,
          service_form_convt_date: null,
          quotation_duplicate: "0",
          q_duplicate_barcode: null,
          quotation_requote: "0",
          q_requote_barcode: null,
          quotation_revise: 0,
          q_revise_barcode: null,
          visit_barcode: null,
          ticket_barcode: null,
          customer: {
            id: 1,
            barcode: "C-000004",
            customer_refrence_id: null,
            display_name: "Business Thrust Techsoft Pvt Ltd.",
            company_name: "Business Thrust Techsoft Pvt Ltd.",
            company_email: null,
            company_number: null,
            company_fax: null,
            company_type: "company",
            reffered_by: "0",
            client_reminders: "1",
            client_follow_up_emails: "1",
            print_email_on_work_order: "1",
            website: "www.bthrust.com",
            uen: null,
            address_line_1: "",
            address_line_2: "",
            unit_number: "",
            postal_code: "",
            country: "Singapore",
            state: "",
            city: "",
            phone: "",
            security_code: "YVJGWITA",
            group: "Commercial",
            group_id: "1",
            entity: "Business Thrust Pte Ltd.",
            entity_id: 1,
            sap_customer_no: null,
            cust_vend_ref_no: null,
            customer_remark: null,
            primary: true,
            activated: true,
            user: "0",
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "1",
            updated_at: "2024-08-24 11:48:19",
            category: null,
            category_color: null,
            can_login: 0,
            role: 3,
            contact_person_count: "2",
            profile_logo: null,
          },
          pending_for_approver: "0",
          property_person: {
            id: 1,
            uuid: null,
            barcode: "CP-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          validity_till: "30",
          type: "1",
          sales_id: 0,
          prepared_by_id: 1,
          billing_person: {
            id: 1,
            uuid: null,
            barcode: "CP-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          visit_converted: "0",
          visit_converted_date: null,
          visit: "0",
          invoice_converted: "0",
          invoice_converted_date: null,
          property: {
            id: 1,
            barcode: "P-000008",
            property_name: null,
            customer: 1,
            supplier: "0",
            contact_person: 1,
            is_same: "1",
            is_tenant: 0,
            unit_no: "#01-583",
            street_1: "ATM, Bendemeer Road Branch (UOB)",
            street_2: "25 Bendemeer Road",
            zip_code: "330025",
            country: "0",
            zone_territory: null,
            state: "0",
            city: "0",
            latitude: null,
            longitude: null,
            remarks: null,
            entity: "Business Thrust Pte Ltd.",
            entity_id: "1",
            type: 1,
            activated: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            property_address:
              "ATM, Bendemeer Road Branch (UOB) 25 Bendemeer Road #01-583 330025",
          },
          billing: {
            id: 1,
            barcode: "P-000008",
            property_name: null,
            customer: 1,
            supplier: "0",
            contact_person: 1,
            is_same: "1",
            is_tenant: 0,
            unit_no: "#01-583",
            street_1: "ATM, Bendemeer Road Branch (UOB)",
            street_2: "25 Bendemeer Road",
            zip_code: "330025",
            country: "0",
            zone_territory: null,
            state: "0",
            city: "0",
            latitude: null,
            longitude: null,
            remarks: null,
            entity: "Business Thrust Pte Ltd.",
            entity_id: "1",
            type: 1,
            activated: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            property_address:
              "ATM, Bendemeer Road Branch (UOB) 25 Bendemeer Road #01-583 330025",
          },
          job_title: "Quotation Title",
          sales: null,
          prepared_by: null,
          attention: null,
          total: 0,
          sub_total: 0,
          is_contract: 0,
          discount_value: 0,
          tax_amount: 0,
          adjustment: 0,
          status: 3,
          date: "2024-08-24",
          open_till: "2024-08-24",
          is_revised: false,
          sent_date: null,
          added_by: {
            id: 1,
            display_name: "Admin",
            user_email: "admin@bthrust.com",
            full_name: "",
          },
          added_at: "2024-08-24 14:14:54",
          updated_by: null,
          updated_at: "2024-08-31 09:15:06",
          qt_status_text: "Rejected",
          qt_status_value: "rejected",
          qt_status_color: "red",
          qt_status_textcolor: "white",
          visit_multiple_count: 0,
          invoice_count: 0,
          created_at: "1 week ago",
          modified_at: "2 days ago",
          contract: null,
          sales_user: null,
          prepared_user: {
            id: 1,
            title: "Mr.",
            first_name: "Super",
            last_name: "Admin",
            display_name: "Admin",
            user_name: "admin",
            user_email: "admin@bthrust.com",
            categories: null,
            password_otp: null,
            user_designation: null,
            phone_number: "+65-98765432",
            signature: null,
            activated: true,
            channel: "customer",
            role: "1",
            from_amount: "0.00",
            to_amount: "0.00",
            has_parent_approver: "0",
            parent_approver: "0",
            engineer: "0",
            added_by: "1",
            added_at: "2024-08-30 09:03:21",
            updated_by: "0",
            updated_at: null,
            email_signature: null,
            force_password_change: "0",
            full_name: "Mr. Super Admin",
            profile_logo: null,
          },
        },
      ],
      moreActions: [
        {
          title: "Import Quotation(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        /*{
          title: "Export Quotation(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },*/
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Draft",
          icon: "mdi-check-all",
          action: "draft",
        },
        {
          title: "Mark as Accepted",
          icon: "mdi-check-all",
          action: "accepted",
        },
        {
          title: "Mark as Rejected",
          icon: "mdi-check-all",
          action: "rejected",
        },
        {
          title: "Mark as Pending",
          icon: "mdi-email-receive",
          action: "pending",
        },
        /*{
          title: "Send as Email",
          icon: "mdi-email-send",
          action: "sent"
        },*/
        {
          title: "Mark as Cancelled",
          icon: "mdi-check-all",
          action: "cancelled",
        },
        {
          title: "Mark as Closed",
          icon: "mdi-check-all",
          action: "closed",
        },
      ],
    };
  },
  components: {
    PageTips,
    draggable,
    EntityDialog,
    CustomStatus,
    PageHeaderCount,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    CustomerDialog,
    CustomerPropertyDialog,
    CustomerPersonDialog,
    Datepicker,
    ImportTemplate,
    DateRangePicker,
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = [];
        }
      },
    },
  },
  methods: {
    getValidityDate(data) {
      if (data.added_at) {
        let date = moment(data.added_at, "YYYY-MM-DD").format("YYYY-MM-DD");
        let validity = moment(date, "YYYY-MM-DD")
          .add(data.validity_till, "days")
          .format("YYYY-MM-DD");
        return this.formatDate(validity);
      }
    },
    getColorType(data) {
      if (data == 1) {
        return "green darken-3";
      } else if (data == 2) {
        return "orange darken-3";
      } else if (data == 3) {
        return "red darken-3";
      }
    },
    getColorText(data) {
      if (data == 1) {
        return "New";
      } else if (data == 2) {
        return "Maintenance";
      } else if (data == 3) {
        return "General";
      }
    },
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    selectCustomerDialog() {
      this.customerDialog = true;
    },
    selectCustomerPersonDialog() {
      this.customerPersonDialog = true;
    },
    selectCustomerPropertyDialog() {
      this.customerPropertyDialog = true;
    },
    resetAll() {
      this.entity = 1;
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.entityDialog = false;
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectEntity(param) {
      this.entity = param;
      this.closeDialog();
      this.$nextTick(() => {
        this.selectCustomerDialog();
      });
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkQuotation();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkQuotation();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkQuotation();
    },

    /* exportQuotation() {
      let downloadURL = process.env.VUE_APP_API_URL + "quotations/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      window.open(downloadURL, "_blank");
    }, */

    exportQuotation() {
      //console.log(row,"rowhhh");
      //console.log(this.searchableArray,"searchableArray");

      let downloadURL = process.env.VUE_APP_API_URL + "quotations/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      //downloadURL.searchParams.append("search", this.queryParams.search);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        // console.log(index, "indexdd")

        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }

        //  downloadURL.searchParams.append(index, row);
      });
      window.open(downloadURL, "_blank");
    },

    customerRedirect(customerId) {
      this.$router.push(
        this.getDefaultRoute("customer.detail", {
          params: { id: customerId },
        })
      );
    },
    checkQuotation() {
      const _this = this;
      if (_this.customer > 0 && _this.customerProperty > 0) {
        _this.$router.push(
          _this.getDefaultRoute("quotation.create", {
            query: {
              customer: _this.customer ?? undefined,
              property: _this.customerProperty ?? undefined,
            },
          })
        );
      }
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkQuotationExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("quotation");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    updateBulkAction(param) {
      const _this = this;
      switch (param) {
        case "draft":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 1,
          });
          break;
        case "accepted":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 2,
          });
          break;
        case "rejected":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 3,
          });
          break;
        case "pending":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 4,
          });
          break;
        case "sent":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 5,
          });
          break;
        case "cancelled":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 6,
          });
          break;
        case "closed":
          _this.bulkQuotationUpdate({
            quotations: _this.selectedRows,
            status: 7,
          });
          break;
      }
    },
    bulkQuotationUpdate(requestObject) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "quotation",
          data: requestObject,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
        });
    },
    bulkQuotationExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "quotations/export",
        })
        .then(({ data }) => {
          saveAs(data, "quotations-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.resetAll();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Quotation",
      },
    ]);
    this.userDetails = JwtService.currentUser();
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;

    //_this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
